@media (max-width: 599px) {
  .header {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding-top: 1.25vh;
  }

  .header .logo-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-y: hidden;
  }

  .header .logo-nav-container .logo-container {
    margin-left: 4vw;
  }

  .header .logo-nav-container .logo-container img {
    max-width: 40%;
  }

  .header .logo-nav-container .mobile-menu {
    margin-right: 5vw;
    cursor: pointer;
  }

  .header .navbar .navbar-options-inactive {
    display: none;
    height: 0;
  }

  .header .navbar .navbar-options-active {
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  .header .navbar .navbar-options-active .option {
    background: #366672;
    width: 100vw;
    font: 700 1.65rem Roboto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  .header .navbar .navbar-options-active .option .link {
    color: #eeeeee;
    text-decoration: none;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }

  .header .navbar .navbar-options-active .option#cta-consulta {
    display: none;
  }

  .header .logo-nav-container .navbar2 {
    display: none;
  }

  .navbar2-list {
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .header {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding-top: 1.25vh;
  }

  .header .logo-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 1.4vh;
    padding-bottom: 1.4vh;
  }

  .header .logo-nav-container .logo-container {
    margin-left: 4vw;
  }

  .header .logo-nav-container .logo-container img {
    max-width: 50%;
    margin-top: -1vh;
  }

  .header .logo-nav-container .mobile-menu {
    margin-right: 6vw;
    cursor: pointer;
  }

  .header .navbar .navbar-options-inactive {
    display: none;
    height: 0;
  }

  .header .navbar .navbar-options-active {
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  .header .navbar .navbar-options-active .option {
    background: #366672;
    width: 100vw;
    font: 700 2.15rem Roboto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
  }

  .header .navbar .navbar-options-active .option .link {
    color: #eeeeee;
    text-decoration: none;
    padding-top: 1.75vh;
    padding-bottom: 1.75vh;
  }

  .header .navbar .navbar-options-active .option#cta-consulta {
    display: none;
  }

  .navbar2-list {
    display: none;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .header {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    padding-top: 1.25vh;
  }

  .header .logo-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 1.7vh;
    padding-bottom: 1.7vh;
  }

  .header .logo-nav-container .logo-container {
    margin: 0;
    margin-left: 5vw;
    padding: 0;
  }

  .header .logo-nav-container .logo-container img {
    max-width: 55%;
    margin-top: -1vh;
    margin-right: 0;
    margin-left: 2vw;
  }

  .header .logo-nav-container .mobile-menu {
    display: none;
  }

  .header .navbar {
    display: none;
  }

  .header .logo-nav-container .navbar2-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    right: 6vw;
  }

  .header .logo-nav-container .navbar2-list .option .link {
    color: #366672;
    font: 700 1.25rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    transition: font-size 0.3s;
    cursor: pointer;
  }

  .header .navbar2-list .option .link:hover {
    font-size: 1.5rem;
  }

  .header .navbar2-list .option a button {
    color: #ffffff;
    background: #366672;
    font: 700 1rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    border-radius: 1rem;
    border: none;
    width: 100%;
    height: 6vh;
    cursor: pointer;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .header {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    padding-top: 1.25vh;
  }

  .header .logo-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }

  .header .logo-nav-container .logo-container {
    margin: 0;
    padding: 0;
  }

  .header .logo-nav-container .logo-container img {
    max-width: 50%;
    margin-top: -1vh;
    margin-right: 0;
  }

  .header .logo-nav-container .mobile-menu {
    display: none;
  }

  .header .navbar {
    display: none;
  }

  .header .logo-nav-container .navbar2-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -2vw;
    position: relative;
    right: 3.5vw;
  }

  .header .logo-nav-container .navbar2-list .option .link {
    color: #366672;
    font: 700 1.3rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    transition: font-size 0.3s;
  }

  .header .navbar2-list .option .link:hover {
    font-size: 1.5rem;
  }

  .header .navbar2-list .option a button {
    color: #ffffff;
    background: #366672;
    font: 700 1.2rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    border-radius: 1rem;
    border: none;
    width: 100%;
    padding: 0.6rem;
    cursor: pointer;
  }

  .header .navbar2-list .option a button:hover {
    font-size: 1.35rem;
  }
}

@media (min-width: 1500px) and (max-width: 1799px) {
  .header {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    padding-top: 1.45vh;
  }

  .header .logo-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 1vh;
    padding-bottom: 1vh;
  }

  .header .logo-nav-container .logo-container {
    margin: 0;
    padding: 0;
  }

  .header .logo-nav-container .logo-container img {
    max-width: 40%;
    margin-top: -1vh;
    margin-right: 0;
  }

  .header .logo-nav-container .mobile-menu {
    display: none;
  }

  .header .navbar {
    display: none;
  }

  .header .logo-nav-container .navbar2-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -4vw;
  }

  .header .logo-nav-container .navbar2-list .option .link {
    color: #366672;
    font: 700 1.4rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    transition: font-size 0.3s;
  }

  .header .navbar2-list .option .link:hover {
    font-size: 1.7rem;
  }

  .header .navbar2-list .option a button {
    color: #ffffff;
    background: #366672;
    font: 700 1.25rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    border-radius: 1rem;
    border: none;
    width: 100%;
    height: 5vh;
    cursor: pointer;
  }
}

@media (min-width: 1800px) and (max-width: 2099px) {
  .header {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    padding-top: 1.5vh;
  }

  .header .logo-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 1vh;
    padding-bottom: 1vh;
  }

  .header .logo-nav-container .logo-container {
    margin: 0;
    padding: 0;
  }

  .header .logo-nav-container .logo-container img {
    max-width: 42%;
    margin-top: -1vh;
    margin-right: 0;
  }

  .header .logo-nav-container .mobile-menu {
    display: none;
  }

  .header .navbar {
    display: none;
  }

  .header .logo-nav-container .navbar2-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -3vw;
  }

  .header .logo-nav-container .navbar2-list .option .link {
    color: #366672;
    font: 700 1.4rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    transition: font-size 0.3s;
  }

  .header .navbar2-list .option .link:hover {
    font-size: 1.55rem;
  }

  .header .navbar2-list .option a button {
    color: #ffffff;
    background: #366672;
    font: 700 1.35rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    border-radius: 1rem;
    border: none;
    width: 100%;
    height: 5.5vh;
    cursor: pointer;
  }
}

@media (min-width: 2100px) and (max-width: 2399px) {
  .header {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    padding-top: 1.5vh;
  }

  .header .logo-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }

  .header .logo-nav-container .logo-container {
    margin: 0;
    padding: 0;
  }

  .header .logo-nav-container .logo-container img {
    max-width: 55%;
    margin-top: -1vh;
    margin-right: 0;
  }

  .header .logo-nav-container .mobile-menu {
    display: none;
  }

  .header .navbar {
    display: none;
  }

  .header .logo-nav-container .navbar2-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -3vw;
  }

  .header .logo-nav-container .navbar2-list .option .link {
    color: #366672;
    font: 700 1.95rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    transition: font-size 0.3s;
  }

  .header .navbar2-list .option .link:hover {
    font-size: 1.85rem;
  }

  .header .navbar2-list .option a button {
    color: #ffffff;
    background: #366672;
    font: 700 1.75rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    border-radius: 1rem;
    border: none;
    width: 100%;
    height: 4.5vh;
    cursor: pointer;
  }
}

@media (min-width: 2400px) {
  .header {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    padding-top: 1.5vh;
  }

  .header .logo-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 1.75vh;
    padding-bottom: 1.75vh;
  }

  .header .logo-nav-container .logo-container {
    margin: 0;
    padding: 0;
  }

  .header .logo-nav-container .logo-container img {
    max-width: 60%;
    margin-top: -1vh;
    margin-right: 0;
  }

  .header .logo-nav-container .mobile-menu {
    display: none;
  }

  .header .navbar {
    display: none;
  }

  .header .logo-nav-container .navbar2-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -3vw;
  }

  .header .logo-nav-container .navbar2-list .option .link {
    color: #366672;
    font: 700 2.4rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    transition: font-size 0.3s;
  }

  .header .navbar2-list .option .link:hover {
    font-size: 2.7rem;
  }

  .header .navbar2-list .option a button {
    color: #ffffff;
    background: #366672;
    font: 700 2rem Roboto;
    text-decoration: none;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    border-radius: 1rem;
    border: none;
    width: 100%;
    height: 4.5vh;
    cursor: pointer;
  }
}