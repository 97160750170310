@media (max-width: 599px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .main-container .first-content {
    background: url("../../assets/bg-home-sm.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #366672;
    padding-bottom: 4vh;
  }

  .main-container .first-content h1,
  h2,
  button {
    margin-left: 4vw;
  }

  .main-container .first-content h1 {
    font: 900 2.25rem Roboto;
    margin-top: 50vh;
  }

  .main-container .first-content h2 {
    font: 700 1.25rem Roboto;
    margin-top: 1vh;
  }

  .main-container .first-content .consulta-btn {
    color: #ffffff;
    background: #366672;
    font: 700 1.25rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    padding: 1rem;
    margin-top: 2.5vh;
    margin-bottom: 6vh;
    cursor: pointer;
  }

  .main-container .second-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .first-section .box {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    padding-left: 2.3rem;
    padding-right: 2.3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 5vh;
    margin-bottom: 1vh;
    width: 100%;
    max-width: 90%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border: 3px solid #366672;
  }

  .main-container .second-content .first-section .box .icon {
    color: #366672;
    margin-right: 5vw;
    margin-left: 5vw;
  }

  .main-container .second-content .first-section .box .subtext {
    display: flex;
    flex-direction: column;
    color: #366672;
  }

  .main-container .second-content .first-section .box .subtext h1 {
    font: 900 1.3rem Roboto;
    color: #366672;
  }

  .main-container .second-content .first-section .img-container {
    background: url("../../assets/rennan-1-sm.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 75vh;
    width: 100%;
    border-radius: 10px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    max-width: 100%;
  }

  .main-container .second-content .first-section h2,
  p {
    margin: 0;
    text-align: center;
    color: #366672;
  }

  .main-container .second-content .first-section h2 {
    font: 900 1.5rem Roboto;
  }

  .main-container .second-content .first-section p {
    font: 700 1rem Roboto;
  }

  .main-container .second-content .second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .second-section .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .second-section .about .box {
    display: none;
  }

  .main-container .second-content .second-section .about .text {
    margin: 0;
    max-width: 80%;
    width: 100%;
    color: rgb(80, 80, 80);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.5vh;
  }

  .main-container .second-content .second-section .about .text .quote-icon {
    display: none;
  }

  .main-container .second-content .second-section .about .text p {
    text-align: center;
    margin-bottom: 3vh;
    font: 400 1rem Roboto;
    color: rgb(80, 80, 80);
  }

  .main-container .second-content .second-section .about .text .saiba-mais-btn {
    color: #366672;
    background: #ffffff;
    font: 900 1.15rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid #366672;
    margin-bottom: 6vh;
    margin-top: 1vh;
    margin-left: 0;
    padding: 1rem;
    cursor: pointer;
  }

  .main-container .third-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #ffffff;
  }

  .main-container .third-content .services {
    display: flex;
    flex-direction: column;
  }

  .main-container .third-content .services .service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .third-content .services .service-content#individual {
    background: url("../../assets/individual-sm.jpg");
    background-size: cover;
  }

  .main-container .third-content .services .service-content#couple {
    background: url("../../assets/casal-sm.jpg");
    background-size: cover;
  }

  .main-container .third-content .services .service-content#hipnose {
    background: url("../../assets/hipnose-sm.jpg");
    background-size: cover;
  }

  .main-container .third-content .services .service-content .icon {
    margin-top: 8vh;
  }

  .main-container .third-content .services .service-content h1 {
    font: 900 2.5rem Roboto;
    margin-top: 3.5vh;
    text-align: center;
  }

  .main-container .third-content .services .service-content .descriptions {
    margin-bottom: 3vh;
    font: 700 1rem Roboto;
    max-width: 90%;
  }

  .main-container .third-content .services .service-content .descriptions p {
    margin: 0;
    margin-top: 5vh;
    text-align: center;
    color: #ffffff;
  }

  .main-container .third-content .services .service-content .button {
    background: #366672;
    font: 700 1.35rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    margin-bottom: 5vh;
    margin-top: 3vh;
    margin-left: 0;
    margin-right: 0;
    padding: 0.75rem;
    cursor: pointer;
  }

  .main-container .third-content .services .service-content .button .link {
    text-decoration: none;
    color: #ffffff;
  }

  .main-container .forth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;
  }

  .main-container .forth-content h1 {
    font: 900 2.5rem Roboto;
    color: #366672;
    text-align: center;
    margin-top: 5vh;
  }

  .main-container .forth-content hr {
    width: 100%;
    max-width: 75%;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .main-container .forth-content p {
    font: 500 1rem Roboto;
    color: rgb(80, 80, 80);
    margin-top: 3vh;
    text-align: center;
    max-width: 80%;
  }

  .main-container .forth-content .reasons {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    max-width: 90%;
    padding-bottom: 6vh;
  }

  .main-container .forth-content .reasons .beautiful-box {
    display: flex;
    flex-direction: row;
    background: #366672;
    border-radius: 10px;
    color: #ffffff;
    align-items: center;
    padding: 0.75rem;
    margin-top: 2vh;
  }

  .main-container .forth-content .reasons .beautiful-box .text {
    display: flex;
    flex-direction: column;
    margin-left: 7.5vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text h2 {
    color: #ffffff;
    text-align: left;
    margin: 0;
    font: 700 1.5rem Roboto;
  }

  .main-container .forth-content .reasons .beautiful-box .text p {
    color: #ffffff;
    text-align: left;
    font: 400 1rem Roboto;
  }

  .main-container .fifth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    margin-top: 6vh;
  }

  .main-container .fifth-content h1 {
    color: #366672;
    font: 900 2rem Roboto;
  }

  .main-container .fifth-content .boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  .main-container .fifth-content .boxes .beautiful-box {
    display: flex;
    flex-direction: column;
    background: #366672;
    max-width: 90%;
    border-radius: 10px;
    margin-top: 4vh;
    margin-bottom: 2vh;
    align-items: center;
    padding-top: 3vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-1 {
    background: #ffffff;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    background: url("../../assets/jessica-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-2 {
    background: #ffffff;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    background: url("../../assets/mario-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-3 {
    background: #ffffff;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    background: url("../../assets/adriana-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p#author {
    font: 500 1rem Roboto;
    margin-top: 1vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .stars {
    display: flex;
    flex-direction: row;
    color: #f9b403;
    margin-top: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p {
    margin-top: 3.5vh;
    text-align: center;
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
    font: 400 1rem Roboto;
  }

  .main-container .fifth-content .boxes .beautiful-box p#depo {
    margin-bottom: 3vh;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .main-container .first-content {
    background: url("../../assets/bg-home-sm.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #366672;
    padding-bottom: 4vh;
  }

  .main-container .first-content h1,
  h2,
  button {
    margin-left: 6vw;
  }

  .main-container .first-content h1 {
    font: 900 3.9rem Roboto;
    margin-top: 52vh;
  }

  .main-container .first-content h2 {
    font: 700 1.7rem Roboto;
    margin-top: 1.25vh;
  }

  .main-container .first-content .consulta-btn {
    color: #ffffff;
    background: #366672;
    font: 700 1.75rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    padding: 1.25rem;
    margin-top: 2.5vh;
    margin-bottom: 6vh;
    cursor: pointer;
  }

  .main-container .second-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .first-section .box {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    padding-left: 2.3rem;
    padding-right: 2.3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 5vh;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border: 3px solid #366672;
  }

  .main-container .second-content .first-section .box .icon {
    color: #366672;
    margin-right: 5vw;
    margin-left: 5vw;
  }

  .main-container .second-content .first-section .box .subtext {
    display: flex;
    flex-direction: column;
    color: #366672;
  }

  .main-container .second-content .first-section .box .subtext h1 {
    font: 900 2.25rem Roboto;
    color: #366672;
  }

  .main-container .second-content .first-section .img-container {
    background: url("../../assets/rennan-1-sm.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 2vh;
    max-width: 100%;
    margin-top: 3vh;
  }

  .main-container .second-content .first-section h2,
  p {
    margin: 0;
    text-align: center;
    color: #366672;
  }

  .main-container .second-content .first-section h2 {
    font: 900 2.25rem Roboto;
  }

  .main-container .second-content .first-section p {
    font: 700 1.5rem Roboto;
  }

  .main-container .second-content .second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .second-section .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .second-section .about .box {
    display: none;
  }

  .main-container .second-content .second-section .about .text {
    margin: 0;
    max-width: 70%;
    width: 100%;
    color: rgb(80, 80, 80);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3vh;
  }

  .main-container .second-content .second-section .about .text .quote-icon {
    display: none;
  }

  .main-container .second-content .second-section .about .text p {
    text-align: center;
    margin-bottom: 3vh;
    font: 400 1.3rem Roboto;
    color: rgb(80, 80, 80);
  }

  .main-container .second-content .second-section .about .text .saiba-mais-btn {
    color: #366672;
    background: #ffffff;
    font: 900 1.85rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid #366672;
    margin-bottom: 6vh;
    margin-top: 1vh;
    margin-left: 0;
    padding: 1rem;
    cursor: pointer;
  }

  .main-container .third-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #ffffff;
  }

  .main-container .third-content .services {
    display: flex;
    flex-direction: column;
  }

  .main-container .third-content .services .service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .third-content .services .service-content#individual {
    background: url("../../assets/individual-sm.jpg");
    background-size: cover;
  }

  .main-container .third-content .services .service-content#couple {
    background: url("../../assets/casal-sm.jpg");
    background-size: cover;
  }

  .main-container .third-content .services .service-content#hipnose {
    background: url("../../assets/hipnose-sm.jpg");
    background-size: cover;
  }

  .main-container .third-content .services .service-content .icon {
    margin-top: 8vh;
  }

  .main-container .third-content .services .service-content h1 {
    font: 900 2.8rem Roboto;
    margin-top: 3.5vh;
    text-align: center;
  }

  .main-container .third-content .services .service-content .descriptions {
    margin-bottom: 3vh;
  }

  .main-container .third-content .services .service-content .descriptions p {
    margin: 0;
    margin-top: 5vh;
    text-align: center;
    color: #ffffff;
    font: 700 1.4rem Roboto;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
  }

  .main-container .third-content .services .service-content .button {
    background: #366672;
    font: 700 1.75rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    margin-bottom: 5vh;
    margin-top: 4vh;
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;
  }

  .main-container .third-content .services .service-content .button .link {
    text-decoration: none;
    color: #ffffff;
  }

  .main-container .forth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;
  }

  .main-container .forth-content h1 {
    font: 900 3.2rem Roboto;
    color: #366672;
    text-align: center;
    margin-top: 5vh;
  }

  .main-container .forth-content hr {
    width: 100%;
    max-width: 75%;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .main-container .forth-content p {
    font: 500 1.35rem Roboto;
    color: rgb(80, 80, 80);
    margin-top: 2vh;
    text-align: center;
    max-width: 90%;
  }

  .main-container .forth-content .reasons {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    max-width: 90%;
    padding-bottom: 6vh;
  }

  .main-container .forth-content .reasons .beautiful-box {
    display: flex;
    flex-direction: row;
    background: #366672;
    border-radius: 10px;
    color: #ffffff;
    align-items: center;
    padding: 0.75rem;
    margin-top: 4vh;
    min-height: 15vh;
  }

  .main-container .forth-content .reasons .beautiful-box .icon {
    margin-left: 4vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text {
    display: flex;
    flex-direction: column;
    margin-left: 6.5vw;
    margin-right: 2vh;
  }

  .main-container .forth-content .reasons .beautiful-box .text h2 {
    color: #ffffff;
    text-align: left;
    margin: 0;
    font: 700 1.9rem Roboto;
  }

  .main-container .forth-content .reasons .beautiful-box .text p {
    color: #ffffff;
    text-align: left;
    font: 400 1.2rem Roboto;
  }

  .main-container .fifth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    margin-top: 8vh;
  }

  .main-container .fifth-content h1 {
    color: #366672;
    font: 900 2.85rem Roboto;
  }

  .main-container .fifth-content .boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  .main-container .fifth-content .boxes .beautiful-box {
    display: flex;
    flex-direction: column;
    background: #366672;
    max-width: 90%;
    width: 100%;
    border-radius: 10px;
    margin-top: 4vh;
    margin-bottom: 2vh;
    align-items: center;
    padding-top: 3vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-1 {
    background: #ffffff;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background: url("../../assets/jessica-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-2 {
    background: #ffffff;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background: url("../../assets/mario-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-3 {
    background: #ffffff;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background: url("../../assets/adriana-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p#author {
    font: 500 1.5rem Roboto;
    margin-top: 1vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .stars {
    display: flex;
    flex-direction: row;
    color: #f9b403;
    margin-top: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p {
    margin-top: 3.5vh;
    text-align: center;
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
    font: 400 1.25rem Roboto;
  }

  .main-container .fifth-content .boxes .beautiful-box p#depo {
    margin-bottom: 3vh;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .main-container .first-content {
    background: url("../../assets/bg-home-md.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #366672;
    min-height: 110vh;
  }

  .main-container .first-content h1,
  h2,
  button {
    margin-left: 4.5vw;
  }

  .main-container .first-content h1 {
    font: 900 2.5rem Roboto;
    margin-top: 41vh;
  }

  .main-container .first-content h2 {
    font: 700 1.15rem Roboto;
    margin-top: 1.25vh;
  }

  .main-container .first-content .consulta-btn {
    color: #ffffff;
    background: #366672;
    font: 700 1.15rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    padding: 1rem;
    margin-top: 2.5vh;
    margin-bottom: 6vh;
    cursor: pointer;
  }

  .main-container .second-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .first-section .box {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    margin-top: 5vh;
    width: 120%;
    max-width: 120%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border: 3px solid #366672;
  }

  .main-container .second-content .first-section .box .icon {
    color: #366672;
    margin-right: 5vw;
    margin-left: 5vw;
  }

  .main-container .second-content .first-section .box .subtext {
    display: flex;
    flex-direction: column;
    color: #366672;
  }

  .main-container .second-content .first-section .box .subtext h1 {
    font: 900 1.75rem Roboto;
    color: #366672;
  }

  .main-container .second-content .first-section .img-container {
    background: url("../../assets/rennan-1-sm.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 70vh;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 2vh;
    max-width: 100%;
    margin-top: 3vh;
  }

  .main-container .second-content .first-section h2,
  p {
    margin: 0;
    text-align: center;
    color: #366672;
  }

  .main-container .second-content .first-section h2 {
    font: 900 1.75rem Roboto;
    margin-top: 2vh;
  }

  .main-container .second-content .first-section p {
    font: 700 1.15rem Roboto;
  }

  .main-container .second-content .second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .second-section .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .second-section .about .box {
    display: none;
  }

  .main-container .second-content .second-section .about .text .quote-icon {
    display: none;
  }

  .main-container .second-content .second-section .about .text {
    margin: 0;
    max-width: 80%;
    width: 100%;
    color: rgb(80, 80, 80);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3vh;
  }

  .main-container .second-content .second-section .about .text p {
    text-align: center;
    margin-bottom: 3vh;
    font: 400 1rem Roboto;
    color: rgb(80, 80, 80);
  }

  .main-container .second-content .second-section .about .text .saiba-mais-btn {
    color: #366672;
    background: #ffffff;
    font: 900 1.35rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid #366672;
    margin-bottom: 7vh;
    margin-top: 2.5vh;
    margin-left: 0;
    padding: 1rem;
    cursor: pointer;
  }

  .main-container .third-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #ffffff;
  }

  .main-container .third-content .services {
    display: flex;
    flex-direction: column;
  }

  .main-container .third-content .services .service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .main-container .third-content .services .service-content#individual {
    background: url("../../assets/individual-sm.jpg");
    background-size: cover;
  }

  .main-container .third-content .services .service-content#couple {
    background: url("../../assets/casal-sm.jpg");
    background-size: cover;
  }

  .main-container .third-content .services .service-content#hipnose {
    background: url("../../assets/hipnose-sm.jpg");
    background-size: cover;
  }

  .main-container .third-content .services .service-content .icon {
    margin-top: 6vh;
  }

  .main-container .third-content .services .service-content h1 {
    font: 900 2.5rem Roboto;
    margin-top: 4vh;
    text-align: center;
  }

  .main-container .third-content .services .service-content .descriptions {
    margin-bottom: 3vh;
  }

  .main-container .third-content .services .service-content .descriptions p {
    margin: 0;
    margin-top: 5vh;
    text-align: center;
    color: #ffffff;
    font: 700 1.1rem Roboto;
  }

  .main-container .third-content .services .service-content .button {
    background: #366672;
    font: 700 1.5rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    margin-bottom: 8vh;
    margin-top: 5vh;
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;
    cursor: pointer;
  }

  .main-container .third-content .services .service-content .button .link {
    text-decoration: none;
    color: #ffffff;
  }

  .main-container .forth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;
  }

  .main-container .forth-content h1 {
    font: 900 2.75rem Roboto;
    color: #366672;
    text-align: center;
    margin-top: 7vh;
  }

  .main-container .forth-content hr {
    width: 100%;
    max-width: 75%;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .main-container .forth-content p {
    font: 500 1.1rem Roboto;
    color: rgb(80, 80, 80);
    margin-top: 1.5vh;
    text-align: center;
  }

  .main-container .forth-content .reasons {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    max-width: 70%;
    padding-bottom: 10vh;
  }

  .main-container .forth-content .reasons .beautiful-box {
    display: flex;
    flex-direction: row;
    background: #366672;
    border-radius: 10px;
    color: #ffffff;
    align-items: center;
    padding: 1rem;
    margin-top: 5vh;
    min-height: 18vh;
  }

  .main-container .forth-content .reasons .beautiful-box .icon {
    margin-left: 5vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text {
    display: flex;
    flex-direction: column;
    margin-left: 6.5vw;
    margin-right: 4vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text h2 {
    color: #ffffff;
    text-align: left;
    margin: 0;
    font: 700 1.5rem Roboto;
  }

  .main-container .forth-content .reasons .beautiful-box .text p {
    color: #ffffff;
    text-align: left;
    font: 400 1.15rem Roboto;
  }

  .main-container .fifth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    margin-top: 12vh;
  }

  .main-container .fifth-content h1 {
    color: #366672;
    font: 900 2.75rem Roboto;
  }

  .main-container .fifth-content .boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  .main-container .fifth-content .boxes .beautiful-box {
    display: flex;
    flex-direction: column;
    background: #366672;
    max-width: 60%;
    width: 100%;
    border-radius: 10px;
    margin-top: 4vh;
    margin-bottom: 3vh;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-1 {
    background: #ffffff;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background: url("../../assets/jessica-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-2 {
    background: #ffffff;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background: url("../../assets/mario-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-3 {
    background: #ffffff;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background: url("../../assets/adriana-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p#author {
    font: 500 1.45rem Roboto;
    margin-top: 1vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .stars {
    display: flex;
    flex-direction: row;
    color: #f9b403;
    margin-top: 2.5vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p {
    margin-top: 3.5vh;
    text-align: center;
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
    font: 400 1.1rem Roboto;
  }

  .main-container .fifth-content .boxes .beautiful-box p#depo {
    margin-bottom: 3vh;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .main-container .first-content {
    background: url("../../assets/bg-home-l.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #366672;
    min-height: 110vh;
  }

  .main-container .first-content h1,
  h2,
  button {
    margin-left: 6vw;
  }

  .main-container .first-content h1 {
    font: 900 3.3rem Roboto;
    margin-top: 40vh;
  }

  .main-container .first-content h2 {
    font: 700 1.2rem Roboto;
    margin-top: 1.25vh;
  }

  .main-container .first-content .consulta-btn {
    color: #ffffff;
    background: #366672;
    font: 700 1.35rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    padding: 1.35rem;
    margin-top: 3.5vh;
    cursor: pointer;
  }

  .main-container .second-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f0f0f0;
  }

  .main-container .second-content .first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-left: 12vw;
  }

  .main-container .second-content .first-section .box {
    margin: 0;
    display: flex;
    flex-direction: row;
    background: #f0f0f0;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 7vh;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border: 3px solid #366672;
  }

  .main-container .second-content .first-section .box .icon {
    color: #366672;
    margin-right: 2vw;
    margin-left: 2vw;
  }

  .main-container .second-content .first-section .box .subtext {
    display: flex;
    flex-direction: column;
    color: #366672;
  }

  .main-container .second-content .first-section .box .subtext h1 {
    font: 900 1.7rem Roboto;
    color: #366672;
  }

  .main-container .second-content .first-section .img-container {
    background: url("../../assets/rennan-1-sm.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 2vh;
    max-width: 100%;
    margin-top: 3vh;
  }

  .main-container .second-content .first-section h2,
  p {
    margin: 0;
    text-align: center;
    color: #366672;
  }

  .main-container .second-content .first-section h2 {
    font: 900 2rem Roboto;
  }

  .main-container .second-content .first-section p {
    font: 700 1.3rem Roboto;
    margin-bottom: 6vh;
  }

  .main-container .second-content .second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 45%;
    width: 100%;
    margin-right: 5vw;
    margin-top: 15vh;
    margin-left: 4vw;
  }

  .main-container .second-content .second-section .about {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .main-container .second-content .second-section .about .box {
    display: none;
  }

  .main-container .second-content .second-section .about .text {
    margin: 0;
    max-width: 90%;
    width: 100%;
    color: rgb(80, 80, 80);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3vh;
  }

  .main-container .second-content .second-section .about .text .quote-icon {
    align-self:flex-end;
    margin-bottom: 3vh;
  }

  .main-container .second-content .second-section .about .text p {
    text-align: justify;
    margin-bottom: 3vh;
    font: 400 1.3rem Roboto;
    color: rgb(80, 80, 80);
  }

  .main-container .second-content .second-section .about .text .saiba-mais-btn {
    color: #366672;
    background: #f0f0f0;
    font: 900 1.5rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid #366672;
    margin-bottom: 7vh;
    margin-top: 1vh;
    margin-left: 0;
    padding: 1rem;
    align-self: flex-start;
    cursor: pointer;
  }

  .main-container .third-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #ffffff;
  }

  .main-container .third-content .services {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .main-container .third-content .services .service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .third-content .services .service-content#individual {
    background: url("../../assets/individual-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content#couple {
    background: url("../../assets/casal-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content#hipnose {
    background: url("../../assets/hipnose-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content .icon {
    margin-top: 8vh;
  }

  .main-container .third-content .services .service-content h1 {
    font: 900 2.2rem Roboto;
    margin-top: 4vh;
    text-align: center;
  }

  .main-container .third-content .services .service-content .descriptions {
    margin-bottom: 3vh;
    max-width: 90%;
  }

  .main-container .third-content .services .service-content .descriptions p {
    margin: 0;
    margin-top: 5vh;
    text-align: center;
    color: #ffffff;
    font: 700 1.10rem Roboto;
  }

  .main-container .third-content .services .service-content .button {
    background: #366672;
    font: 700 1.45rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    margin-bottom: 8vh;
    margin-top: 4vh;
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;
  }

  .main-container .third-content .services .service-content .button .link {
    text-decoration: none;
    color: #ffffff;
  }

  .main-container .forth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;
  }

  .main-container .forth-content h1 {
    font: 900 3rem Roboto;
    color: #366672;
    text-align: center;
    margin-top: 8vh;
  }

  .main-container .forth-content hr {
    width: 100%;
    max-width: 75%;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .main-container .forth-content p {
    font: 500 1.1rem Roboto;
    color: rgb(80, 80, 80);
    margin-top: 1.5vh;
    text-align: center;
  }

  .main-container .forth-content .reasons {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    max-width: 60%;
    padding-bottom: 10vh;
  }

  .main-container .forth-content .reasons .beautiful-box {
    display: flex;
    flex-direction: row;
    background: #366672;
    border-radius: 10px;
    color: #ffffff;
    align-items: center;
    padding: 1rem;
    margin-top: 5vh;
    min-height: 18vh;
  }

  .main-container .forth-content .reasons .beautiful-box .icon {
    margin-left: 6vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text {
    display: flex;
    flex-direction: column;
    margin-left: 6.5vw;
    margin-right: 4vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text h2 {
    color: #ffffff;
    text-align: left;
    margin: 0;
    font: 700 1.5rem Roboto;
  }

  .main-container .forth-content .reasons .beautiful-box .text p {
    color: #ffffff;
    text-align: left;
    font: 400 1.075rem Roboto;
  }

  .main-container .fifth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    margin-top: 8vh;
  }

  .main-container .fifth-content h1 {
    color: #366672;
    font: 900 3.25rem Roboto;
  }

  .main-container .fifth-content .boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 90%;
    max-width: 100%;
  }

  .main-container .fifth-content .beautiful-box {
    display: flex;
    flex-direction: column;
    background: #366672;
    max-width: 30%;
    width: 100%;
    border-radius: 10px;
    margin-top: 4vh;
    margin-bottom: 5vh;
    margin-left: 2vw;
    margin-right: 2vw;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 2vh;
  }

  .main-container .fifth-content .beautiful-box .img-container-1 {
    background: #ffffff;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background: url("../../assets/jessica-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .beautiful-box .img-container-2 {
    background: #ffffff;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background: url("../../assets/mario-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .beautiful-box .img-container-3 {
    background: #ffffff;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background: url("../../assets/adriana-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .beautiful-box p#author {
    font: 500 1.5rem Roboto;
    margin-top: 1vh;
  }

  .main-container .fifth-content .beautiful-box .stars {
    display: flex;
    flex-direction: row;
    color: #f9b403;
    margin-top: 2.5vh;
  }

  .main-container .fifth-content .beautiful-box p {
    margin-top: 3.5vh;
    text-align: center;
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
    font: 400 1rem Roboto;
  }

  .main-container .fifth-content .beautiful-box p#depo {
    margin-bottom: 3vh;
  }
}

@media (min-width: 1500px) and (max-width: 1799px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .main-container .first-content {
    background: url("../../assets/bg-home-l.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #366672;
    min-height: 100vh;
  }

  .main-container .first-content h1,
  h2,
  button {
    margin-left: 6vw;
  }

  .main-container .first-content h1 {
    font: 900 3.5rem Roboto;
    margin-top: 40vh;
  }

  .main-container .first-content h2 {
    font: 700 1.3rem Roboto;
    margin-top: 1.25vh;
  }

  .main-container .first-content .consulta-btn {
    color: #ffffff;
    background: #366672;
    font: 700 1.2rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    padding: 1.35rem;
    margin-top: 3.5vh;
    cursor: pointer;
  }

  .main-container .second-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f0f0f0;
  }

  .main-container .second-content .first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-left: 10vw;
  }

  .main-container .second-content .first-section .box {
    margin: 0;
    display: flex;
    flex-direction: row;
    background: #f0f0f0;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 5vh;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border: 3px solid #366672;
  }

  .main-container .second-content .first-section .box .icon {
    color: #366672;
    margin-right: 2vw;
    margin-left: 2vw;
  }

  .main-container .second-content .first-section .box .subtext {
    display: flex;
    flex-direction: column;
    color: #366672;
  }

  .main-container .second-content .first-section .box .subtext h1 {
    font: 900 2rem Roboto;
    color: #366672;
  }

  .main-container .second-content .first-section .img-container {
    background: url("../../assets/rennan-1-sm.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 2vh;
    max-width: 100%;
    margin-top: 3vh;
  }

  .main-container .second-content .first-section h2,
  p {
    margin: 0;
    text-align: center;
    color: #366672;
  }

  .main-container .second-content .first-section h2 {
    font: 900 2rem Roboto;
  }

  .main-container .second-content .first-section p {
    font: 700 1.35rem Roboto;
    margin-bottom: 6.5vh;
  }

  .main-container .second-content .second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 45%;
    width: 100%;
    margin-right: 5vw;
    margin-top: 15vh;
    margin-left: 4vw;
  }

  .main-container .second-content .second-section .about {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .main-container .second-content .second-section .about .box {
    display: none;
  }

  .main-container .second-content .second-section .about .text {
    margin: 0;
    max-width: 80%;
    width: 100%;
    color: rgb(80, 80, 80);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
  }

  .main-container .second-content .second-section .about .text .quote-icon {
    align-self:flex-end;
    margin-bottom: 5vh;
  }

  .main-container .second-content .second-section .about .text p {
    text-align: justify;
    margin-bottom: 5vh;
    font: 400 1.5rem Roboto;
    color: rgb(80, 80, 80);
  }

  .main-container .second-content .second-section .about .text .saiba-mais-btn {
    color: #366672;
    background: #f0f0f0;
    font: 900 1.5rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid #366672;
    margin-bottom: 7vh;
    margin-left: 0;
    padding: 1rem;
    align-self: flex-start;
    cursor: pointer;
  }

  .main-container .third-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #ffffff;
  }

  .main-container .third-content .services {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .main-container .third-content .services .service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .third-content .services .service-content#individual {
    background: url("../../assets/individual-sm.jpg");
    background-size: cover;
    width: 95%;
  }

  .main-container .third-content .services .service-content#couple {
    background: url("../../assets/casal-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content#hipnose {
    background: url("../../assets/hipnose-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content .icon {
    margin-top: 8vh;
  }

  .main-container .third-content .services .service-content h1 {
    font: 900 2.5rem Roboto;
    margin-top: 4vh;
    text-align: center;
  }

  .main-container .third-content .services .service-content .descriptions {
    margin-bottom: 3vh;
    max-width: 90%;
  }

  .main-container .third-content .services .service-content .descriptions p {
    margin: 0;
    margin-top: 5vh;
    text-align: center;
    color: #ffffff;
    font: 700 1.35rem Roboto;
  }

  .main-container .third-content .services .service-content .button {
    background: #366672;
    font: 700 1.4rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    margin-bottom: 8vh;
    margin-top: 4vh;
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;
  }

  .main-container .third-content .services .service-content .button .link {
    text-decoration: none;
    color: #ffffff;
  }

  .main-container .forth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;
    padding-bottom: 12vh;
  }

  .main-container .forth-content h1 {
    font: 900 3.25rem Roboto;
    color: #366672;
    text-align: center;
    margin-top: 7vh;
  }

  .main-container .forth-content hr {
    width: 100%;
    max-width: 75%;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .main-container .forth-content p {
    font: 500 1.2rem Roboto;
    color: rgb(80, 80, 80);
    margin-top: 1.5vh;
    text-align: center;
  }

  .main-container .forth-content .reasons {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    max-width: 50%;
  }

  .main-container .forth-content .reasons .beautiful-box {
    display: flex;
    flex-direction: row;
    background: #366672;
    border-radius: 10px;
    color: #ffffff;
    align-items: center;
    padding: 1.25rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 5vh;
    min-height: 18vh;
  }

  .main-container .forth-content .reasons .beautiful-box .icon {
    margin-left: 6vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text {
    display: flex;
    flex-direction: column;
    margin-left: 6.5vw;
    margin-right: 4vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text h2 {
    color: #ffffff;
    text-align: left;
    margin: 0;
    font: 700 1.65rem Roboto;
  }

  .main-container .forth-content .reasons .beautiful-box .text p {
    color: #ffffff;
    text-align: left;
    font: 400 1.1rem Roboto;
  }

  .main-container .fifth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    margin-top: 6vh;
  }

  .main-container .fifth-content h1 {
    color: #366672;
    font: 900 3.1rem Roboto;
  }

  .main-container .fifth-content .boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 90%;
    max-width: 100%;
  }

  .main-container .fifth-content .boxes .beautiful-box {
    display: flex;
    flex-direction: column;
    background: #366672;
    max-width: 30%;
    width: 100%;
    border-radius: 10px;
    margin-top: 4vh;
    margin-bottom: 5vh;
    margin-left: 2vw;
    margin-right: 2vw;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-1 {
    background: #ffffff;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background: url("../../assets/jessica-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-2 {
    background: #ffffff;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background: url("../../assets/mario-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-3 {
    background: #ffffff;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background: url("../../assets/adriana-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p#author {
    font: 500 1.5rem Roboto;
    margin-top: 1vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .stars {
    display: flex;
    flex-direction: row;
    color: #f9b403;
    margin-top: 2.5vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p {
    margin-top: 3.5vh;
    text-align: justify;
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
    font: 400 1.15rem Roboto;
  }

  .main-container .fifth-content .boxes .beautiful-box p#depo {
    margin-bottom: 3vh;
  }
}

@media (min-width: 1800px) and (max-width: 2099px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .main-container .first-content {
    background: url("../../assets/bg-home-md.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #366672;
    min-height: 100vh;
  }

  .main-container .first-content h1,
  h2,
  button {
    margin-left: 7vw;
  }

  .main-container .first-content h1 {
    font: 900 3.7rem Roboto;
    margin-top: 40vh;
  }

  .main-container .first-content h2 {
    font: 700 1.45rem Roboto;
    margin-top: 1.35vh;
  }

  .main-container .first-content .consulta-btn {
    color: #ffffff;
    background: #366672;
    font: 700 1.4rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    padding: 1.5rem;
    margin-top: 3.5vh;
    padding: 1.35rem;
    cursor: pointer;
  }

  .main-container .second-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f0f0f0;
  }

  .main-container .second-content .first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-left: 10vw;
  }

  .main-container .second-content .first-section .box {
    margin: 0;
    display: flex;
    flex-direction: row;
    background: #f0f0f0;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 5vh;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border: 3px solid #366672;
  }

  .main-container .second-content .first-section .box .icon {
    color: #366672;
    margin-right: 2vw;
    margin-left: 2vw;
  }

  .main-container .second-content .first-section .box .subtext {
    display: flex;
    flex-direction: column;
    color: #366672;
  }

  .main-container .second-content .first-section .box .subtext h1 {
    font: 900 2rem Roboto;
    color: #366672;
  }

  .main-container .second-content .first-section .img-container {
    background: url("../../assets/rennan-1-sm.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 2vh;
    max-width: 95%;
    margin-top: 3vh;
  }

  .main-container .second-content .first-section h2,
  p {
    margin: 0;
    text-align: center;
    color: #366672;
  }

  .main-container .second-content .first-section h2 {
    font: 900 2.15rem Roboto;
    margin-top: -1vh;
  }

  .main-container .second-content .first-section p {
    font: 700 1.3rem Roboto;
    margin-bottom: 6vh;
  }

  .main-container .second-content .second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 45%;
    width: 100%;
    margin-right: 5vw;
    margin-top: 15vh;
    margin-left: 4vw;
  }

  .main-container .second-content .second-section .about {
    display: flex;
    flex-direction: column;
    align-items: left;
  }


  .main-container .second-content .second-section .about .box {
    display: none;
  }

  .main-container .second-content .second-section .about .text {
    margin: 0;
    max-width: 80%;
    width: 100%;
    color: rgb(80, 80, 80);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .second-content .second-section .about .text .quote-icon {
    align-self:flex-end;
    margin-bottom: 2vh;
  }

  .main-container .second-content .second-section .about .text p {
    text-align: justify;
    margin-bottom: 3vh;
    font: 400 1.5rem Roboto;
    color: rgb(80, 80, 80);
    padding-top: 5vh;
  }

  .main-container .second-content .second-section .about .text .saiba-mais-btn {
    color: #366672;
    background: #f0f0f0;
    font: 900 1.75rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid #366672;
    margin-bottom: 7vh;
    margin-top: 1vh;
    margin-left: 0;
    padding: 1.2rem;
    align-self: flex-start;
    cursor: pointer;
  }

  .main-container .third-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #ffffff;
  }

  .main-container .third-content .services {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .main-container .third-content .services .service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .third-content .services .service-content#individual {
    background: url("../../assets/individual-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content#couple {
    background: url("../../assets/casal-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content#hipnose {
    background: url("../../assets/hipnose-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content .icon {
    margin-top: 8vh;
  }

  .main-container .third-content .services .service-content h1 {
    font: 900 2.7rem Roboto;
    margin-top: 4vh;
    text-align: center;
  }

  .main-container .third-content .services .service-content .descriptions {
    margin-bottom: 3vh;
    max-width: 90%;
  }

  .main-container .third-content .services .service-content .descriptions p {
    margin: 0;
    margin-top: 5vh;
    text-align: center;
    color: #ffffff;
    font: 700 1.25rem Roboto;
  }

  .main-container .third-content .services .service-content .button {
    background: #366672;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    margin-bottom: 4vh;
    margin-top: 4vh;
    margin-left: 0;
    margin-right: 0;
    padding: 1.25rem;
  }

  .main-container .third-content .services .service-content .button .link {
    text-decoration: none;
    color: #ffffff;
    font: 700 1.7rem Roboto;
  }

  .main-container .forth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;
    padding-bottom: 12vh;
  }

  .main-container .forth-content h1 {
    font: 900 3.35rem Roboto;
    color: #366672;
    text-align: center;
    margin-top: 8vh;
  }

  .main-container .forth-content hr {
    width: 100%;
    max-width: 79%;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .main-container .forth-content p {
    font: 500 1.25rem Roboto;
    color: rgb(80, 80, 80);
    margin-top: 1.5vh;
    text-align: center;
  }

  .main-container .forth-content .reasons {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    max-width: 50%;
  }

  .main-container .forth-content .reasons .beautiful-box {
    display: flex;
    flex-direction: row;
    background: #366672;
    border-radius: 10px;
    color: #ffffff;
    align-items: center;
    padding: 1.25rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 5vh;
    min-height: 18vh;
  }

  .main-container .forth-content .reasons .beautiful-box .icon {
    margin-left: 6vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text {
    display: flex;
    flex-direction: column;
    margin-left: 6.5vw;
    margin-right: 4vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text h2 {
    color: #ffffff;
    text-align: left;
    margin: 0;
    font: 700 1.7rem Roboto;
  }

  .main-container .forth-content .reasons .beautiful-box .text p {
    color: #ffffff;
    text-align: left;
    font: 400 1.25rem Roboto;
  }

  .main-container .fifth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    margin-top: 8vh;
  }

  .main-container .fifth-content h1 {
    color: #366672;
    font: 900 3.35rem Roboto;
  }

  .main-container .fifth-content .boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 90%;
    max-width: 100%;
  }

  .main-container .fifth-content .boxes .beautiful-box {
    display: flex;
    flex-direction: column;
    background: #366672;
    max-width: 30%;
    width: 100%;
    border-radius: 10px;
    margin-top: 4vh;
    margin-bottom: 5vh;
    margin-left: 2vw;
    margin-right: 2vw;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 1vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-1 {
    background: #ffffff;
    border-radius: 50%;
    height: 105px;
    width: 105px;
    background: url("../../assets/jessica-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-2 {
    background: #ffffff;
    border-radius: 50%;
    height: 105px;
    width: 105px;
    background: url("../../assets/mario-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-3 {
    background: #ffffff;
    border-radius: 50%;
    height: 105px;
    width: 105px;
    background: url("../../assets/adriana-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p#author {
    font: 500 1.6rem Roboto;
    margin-top: 1vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .stars {
    display: flex;
    flex-direction: row;
    color: #f9b403;
    margin-top: 2.5vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p {
    margin-top: 3.5vh;
    text-align: center;
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
    font: 400 1.25rem Roboto;
  }

  .main-container .fifth-content .boxes .beautiful-box p#depo {
    margin-bottom: 3vh;
  }
}

@media (min-width: 2100px) and (max-width: 2399px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .main-container .first-content {
    background: url("../../assets/bg-home-md.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #366672;
    min-height: 100vh;
  }

  .main-container .first-content h1,
  h2,
  button {
    margin-left: 6vw;
  }

  .main-container .first-content h1 {
    font: 900 5.3rem Roboto;
    margin-top: 47.5vh;
  }

  .main-container .first-content h2 {
    font: 700 2rem Roboto;
    margin-top: 1.25vh;
  }

  .main-container .first-content .consulta-btn {
    color: #ffffff;
    background: #366672;
    font: 700 2.4rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    padding: 1.75rem;
    margin-top: 3.5vh;
    cursor: pointer;
  }

  .main-container .second-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f0f0f0;
  }

  .main-container .second-content .first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-left: 10vw;
  }

  .main-container .second-content .first-section .box {
    margin: 0;
    display: flex;
    flex-direction: row;
    background: #f0f0f0;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 5vh;
    margin-bottom: -4vh;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border: 3px solid #366672;
  }

  .main-container .second-content .first-section .box .icon {
    color: #366672;
    margin-right: 2vw;
    margin-left: 2vw;
  }

  .main-container .second-content .first-section .box .subtext {
    display: flex;
    flex-direction: column;
    color: #366672;
  }

  .main-container .second-content .first-section .box .subtext h1 {
    font: 900 2.75rem Roboto;
    color: #366672;
  }

  .main-container .second-content .first-section .img-container {
    background: url("../../assets/rennan-1-sm.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh;
    width: 100%;
    border-radius: 10px;
    margin-bottom: -3vh;
    max-width: 95%;
    margin-top: 4vh;
  }

  .main-container .second-content .first-section h2,
  p {
    margin: 0;
    text-align: center;
    color: #366672;
  }

  .main-container .second-content .first-section h2 {
    font: 900 2.5rem Roboto;
    margin-top: 2vh;
  }

  .main-container .second-content .first-section p {
    font: 700 1.75rem Roboto;
    margin-bottom: 5vh;
  }

  .main-container .second-content .second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 45%;
    width: 100%;
    margin-right: 5vw;
    margin-top: 15vh;
    margin-left: 1vw;
  }

  .main-container .second-content .second-section .about {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .main-container .second-content .second-section .about .box {
    display: none;
  }

  .main-container .second-content .second-section .about .text {
    margin: 0;
    max-width: 80%;
    width: 100%;
    color: rgb(80, 80, 80);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vh;
  }

  .main-container .second-content .second-section .about .text .quote-icon {
    align-self:flex-end;
    margin-bottom: 2vh;
  }

  .main-container .second-content .second-section .about .text p {
    text-align: justify;
    margin-bottom: 3vh;
    font: 400 2.2rem Roboto;
    color: rgb(80, 80, 80);
  }

  .main-container .second-content .second-section .about .text .saiba-mais-btn {
    color: #366672;
    background: #f0f0f0;
    font: 900 2.25rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid #366672;
    margin-bottom: 7vh;
    margin-top: 1.2vh;
    margin-left: 0;
    padding: 1.2rem;
    align-self: flex-start;
    cursor: pointer;
  }

  .main-container .third-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #ffffff;
  }

  .main-container .third-content .services {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .main-container .third-content .services .service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .third-content .services .service-content#individual {
    background: url("../../assets/individual-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content#couple {
    background: url("../../assets/casal-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content#hipnose {
    background: url("../../assets/hipnose-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content .icon {
    margin-top: 8vh;
  }

  .main-container .third-content .services .service-content h1 {
    font: 900 3.5rem Roboto;
    margin-top: 4vh;
    text-align: center;
  }

  .main-container .third-content .services .service-content .descriptions {
    margin-bottom: 3vh;
    max-width: 90%;
  }

  .main-container .third-content .services .service-content .descriptions p {
    margin: 0;
    margin-top: 5vh;
    text-align: center;
    color: #ffffff;
    font: 700 1.8rem Roboto;
  }

  .main-container .third-content .services .service-content .button {
    background: #366672;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    margin-bottom: 8vh;
    margin-top: 4vh;
    margin-left: 0;
    margin-right: 0;
    padding: 1.35rem;
  }

  .main-container .third-content .services .service-content .button .link {
    text-decoration: none;
    color: #ffffff;
    font: 700 2.1rem Roboto;
  }

  .main-container .forth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;
    padding-bottom: 12vh;
  }

  .main-container .forth-content h1 {
    font: 900 5.35rem Roboto;
    color: #366672;
    text-align: center;
    margin-top: 10vh;
  }

  .main-container .forth-content hr {
    width: 100%;
    max-width: 75%;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .main-container .forth-content p {
    font: 500 2rem Roboto;
    color: rgb(80, 80, 80);
    margin-top: 1.5vh;
    text-align: center;
  }

  .main-container .forth-content .reasons {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    max-width: 50%;
  }

  .main-container .forth-content .reasons .beautiful-box {
    display: flex;
    flex-direction: row;
    background: #366672;
    border-radius: 10px;
    color: #ffffff;
    align-items: center;
    padding: 1.25rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 5vh;
    min-height: 18vh;
  }

  .main-container .forth-content .reasons .beautiful-box .icon {
    margin-left: 6vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text {
    display: flex;
    flex-direction: column;
    margin-left: 6.5vw;
    margin-right: 4vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text h2 {
    color: #ffffff;
    text-align: left;
    margin: 0;
    font: 700 2.75rem Roboto;
  }

  .main-container .forth-content .reasons .beautiful-box .text p {
    color: #ffffff;
    text-align: left;
    font: 400 1.85rem Roboto;
  }

  .main-container .fifth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    margin-top: 8vh;
  }

  .main-container .fifth-content h1 {
    color: #366672;
    font: 900 4.4rem Roboto;
  }

  .main-container .fifth-content .boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 90%;
    max-width: 100%;
  }

  .main-container .fifth-content .boxes .beautiful-box {
    display: flex;
    flex-direction: column;
    background: #366672;
    max-width: 30%;
    width: 100%;
    border-radius: 10px;
    margin-top: 4vh;
    margin-bottom: 5vh;
    margin-left: 2vw;
    margin-right: 2vw;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-1 {
    background: #ffffff;
    border-radius: 50%;
    height: 110px;
    width: 110px;
    background: url("../../assets/jessica-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-2 {
    background: #ffffff;
    border-radius: 50%;
    height: 110px;
    width: 110px;
    background: url("../../assets/mario-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-3 {
    background: #ffffff;
    border-radius: 50%;
    height: 110px;
    width: 110px;
    background: url("../../assets/adriana-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p#author {
    font: 500 2.3rem Roboto;
    margin-top: 1vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .stars {
    display: flex;
    flex-direction: row;
    color: #f9b403;
    margin-top: 2.5vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p {
    margin-top: 3.5vh;
    text-align: justify;
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
    font: 400 1.75rem Roboto;
  }

  .main-container .fifth-content .boxes .beautiful-box p#depo {
    margin-bottom: 3.5vh;
  }
}

@media (min-width: 2400px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .main-container .first-content {
    background: url("../../assets/bg-home-md.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #366672;
    min-height: 100vh;
  }

  .main-container .first-content h1,
  h2,
  button {
    margin-left: 6vw;
  }

  .main-container .first-content h1 {
    font: 900 6rem Roboto;
    margin-top: 40vh;
  }

  .main-container .first-content h2 {
    font: 700 2.65rem Roboto;
    margin-top: 1.25vh;
  }

  .main-container .first-content .consulta-btn {
    color: #ffffff;
    background: #366672;
    font: 700 2.75rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: none;
    padding: 1.75rem;
    margin-top: 3.5vh;
    cursor: pointer;
  }

  .main-container .second-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f0f0f0;
  }

  .main-container .second-content .first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-left: 10vw;
  }

  .main-container .second-content .first-section .box {
    margin: 0;
    display: flex;
    flex-direction: row;
    background: #f0f0f0;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    margin-top: 5vh;
    margin-bottom: -4vh;
    width: 100%;
    max-width: 90%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border: 3px solid #366672;
  }

  .main-container .second-content .first-section .box .icon {
    color: #366672;
    margin-right: 2vw;
    margin-left: 2vw;
  }

  .main-container .second-content .first-section .box .subtext {
    display: flex;
    flex-direction: column;
    color: #366672;
  }

  .main-container .second-content .first-section .box .subtext h1 {
    font: 900 3.5rem Roboto;
    color: #366672;
  }

  .main-container .second-content .first-section .img-container {
    background: url("../../assets/rennan-1-l.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh;
    width: 100%;
    border-radius: 10px;
    margin-bottom: -3vh;
    max-width: 95%;
    margin-top: 7vh;
  }

  .main-container .second-content .first-section h2,
  p {
    margin: 0;
    text-align: center;
    color: #366672;
  }

  .main-container .second-content .first-section h2 {
    font: 900 3.25rem Roboto;
    margin-top: 5vh;
  }

  .main-container .second-content .first-section p {
    font: 700 2rem Roboto;
    margin-bottom: 5vh;
  }

  .main-container .second-content .second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 45%;
    width: 100%;
    margin-right: 5vw;
    margin-top: 15vh;
    margin-left: 1vw;
  }

  .main-container .second-content .second-section .about {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .main-container .second-content .second-section .about .box {
    display: none;
  }

  .main-container .second-content .second-section .about .text {
    margin: 0;
    max-width: 80%;
    width: 100%;
    color: rgb(80, 80, 80);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vh;
  }

  .main-container .second-content .second-section .about .text .quote-icon {
    align-self:flex-end;
    margin-bottom: 2vh;
  }

  .main-container .second-content .second-section .about .text p {
    text-align: justify;
    margin-bottom: 3vh;
    font: 400 3rem Roboto;
    color: rgb(80, 80, 80);
  }

  .main-container .second-content .second-section .about .text .saiba-mais-btn {
    color: #366672;
    background: #f0f0f0;
    font: 900 3rem Roboto;
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid #366672;
    margin-bottom: 7vh;
    margin-top: 1.2vh;
    margin-left: 0;
    padding: 1.2rem;
    align-self: flex-start;
    cursor: pointer;
  }

  .main-container .third-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #ffffff;
  }

  .main-container .third-content .services {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .main-container .third-content .services .service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-container .third-content .services .service-content#individual {
    background: url("../../assets/individual-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content#couple {
    background: url("../../assets/casal-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content#hipnose {
    background: url("../../assets/hipnose-sm.jpg");
    background-size: cover;
    width: 100%;
  }

  .main-container .third-content .services .service-content .icon {
    margin-top: 8vh;
  }

  .main-container .third-content .services .service-content h1 {
    font: 900 4rem Roboto;
    margin-top: 4vh;
    text-align: center;
  }

  .main-container .third-content .services .service-content .descriptions {
    margin-bottom: 3vh;
    max-width: 90%;
  }

  .main-container .third-content .services .service-content .descriptions p {
    margin: 0;
    margin-top: 5vh;
    text-align: center;
    color: #ffffff;
    font: 700 2.3rem Roboto;
  }

  .main-container .third-content .services .service-content .button {
    background: #366672;
    text-decoration: none;
    border-radius: 15px;
    border: none;
    margin-bottom: 8vh;
    margin-top: 4vh;
    margin-left: 0;
    margin-right: 0;
    padding: 1.35rem;
    cursor: pointer;
  }

  .main-container .third-content .services .service-content .button .link {
    text-decoration: none;
    color: #ffffff;
    font: 700 2.75rem Roboto;
  }

  .main-container .forth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;
    padding-bottom: 12vh;
  }

  .main-container .forth-content h1 {
    font: 900 6rem Roboto;
    color: #366672;
    text-align: center;
    margin-top: 10vh;
  }

  .main-container .forth-content hr {
    width: 100%;
    max-width: 75%;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .main-container .forth-content p {
    font: 500 2.5rem Roboto;
    color: rgb(80, 80, 80);
    margin-top: 1.5vh;
    text-align: center;
  }

  .main-container .forth-content .reasons {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    max-width: 50%;
  }

  .main-container .forth-content .reasons .beautiful-box {
    display: flex;
    flex-direction: row;
    background: #366672;
    border-radius: 10px;
    color: #ffffff;
    align-items: center;
    padding: 1.25rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 5vh;
    min-height: 18vh;
  }

  .main-container .forth-content .reasons .beautiful-box .icon {
    margin-left: 6vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text {
    display: flex;
    flex-direction: column;
    margin-left: 6.5vw;
    margin-right: 4vw;
  }

  .main-container .forth-content .reasons .beautiful-box .text h2 {
    color: #ffffff;
    text-align: left;
    margin: 0;
    font: 700 3.3rem Roboto;
  }

  .main-container .forth-content .reasons .beautiful-box .text p {
    color: #ffffff;
    text-align: left;
    font: 400 2rem Roboto;
  }

  .main-container .fifth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    margin-top: 6vh;
  }

  .main-container .fifth-content h1 {
    color: #366672;
    font: 900 5rem Roboto;
  }

  .main-container .fifth-content .boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 90%;
    max-width: 100%;
  }

  .main-container .fifth-content .boxes .beautiful-box {
    display: flex;
    flex-direction: column;
    background: #366672;
    max-width: 30%;
    width: 100%;
    border-radius: 10px;
    margin-top: 4vh;
    margin-bottom: 5vh;
    margin-left: 2vw;
    margin-right: 2vw;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-1 {
    background: #ffffff;
    border-radius: 50%;
    height: 125px;
    width: 125px;
    background: url("../../assets/jessica-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-2 {
    background: #ffffff;
    border-radius: 50%;
    height: 125px;
    width: 125px;
    background: url("../../assets/mario-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .img-container-3 {
    background: #ffffff;
    border-radius: 50%;
    height: 125px;
    width: 125px;
    background: url("../../assets/adriana-mini.png");
    background-size: cover;
    background-position: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p#author {
    font: 500 3rem Roboto;
    margin-top: 1vh;
  }

  .main-container .fifth-content .boxes .beautiful-box .stars {
    display: flex;
    flex-direction: row;
    color: #f9b403;
    margin-top: 2.5vh;
  }

  .main-container .fifth-content .boxes .beautiful-box p {
    margin-top: 3.5vh;
    text-align: justify;
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
    font: 400 2.3rem Roboto;
  }

  .main-container .fifth-content .boxes .beautiful-box p#depo {
    margin-bottom: 3.5vh;
  }
}